import { SAVE_CUSTOMER, RESET_CUSTOMER } from "../constants/actionTypes";

const INITIAL_STATE = {
  status: "CREATED",
  profile: "claro_recarga_basic",
  allowedCreditCardsChanges: "3",
  availableCreditCardSlots: "3",
  remainingSpendingLimit: "",
  traits: {},
};

const applyCustomerPayload = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    status: payload.status,
    profile: payload.profile.name,
    allowedCreditCardsChanges: payload.profile.allowed_credit_card_changes,
    availableCreditCardSlots: payload.profile.available_credit_card_slots,
    remainingSpendingLimit: payload.profile.remaining_spending_limit,
    traits: payload?.traits,
  };
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER:
      return applyCustomerPayload(state, action);
    case RESET_CUSTOMER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default customerReducer;
